.startScreen {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  min-height: 100vh;
  width: 100%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  position: relative;
  overflow: hidden;
  isolation: isolate;
}

.startScreen::after {
  content: "";
  position: absolute;
  width: 250rem;
  height: 100rem;
  background: #88DF86;
  -webkit-transform: rotate(-25.3deg) translateY(50rem);
          transform: rotate(-25.3deg) translateY(50rem);
  z-index: -2;
}

.startScreen h1 {
  position: absolute;
  color: #88DF86;
  font-weight: bold;
  mix-blend-mode: color-dodge;
  font-size: 2rem;
}
