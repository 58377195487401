.questions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  text-align: center;
  padding: 1rem;
}

.questions h1 {
  color: #88df86;
  font-size: 3rem;
  text-align: center;
  font-weight: bold;
}

.questions label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.questions label input {
  background: #f3f3f3;
  padding: 1rem;
  border: 1px solid #e5e5e5;
  border-radius: 10rem;
  font-size: 17px;
}

.questions label input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.312);
  font-weight: bold;
}

.questions label input:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.312);
  font-weight: bold;
}

.questions label input::-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.312);
  font-weight: bold;
}

.questions label input::placeholder {
  color: rgba(0, 0, 0, 0.312);
  font-weight: bold;
}

.questions label p {
  border-bottom: 2px solid #88df86;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  padding: 0 0.5rem;
  margin-left: 0.8rem;
  color: #88df86;
  font-size: 1.3rem;
  font-weight: bold;
}

.questions fieldset {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  border: 2px solid #88df86;
  color: #88df86;
  font-weight: bold;
  font-size: 1.4rem;
  gap: 1rem;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  text-align: left;
  /* HIDE RADIO */
  /* IMAGE STYLES */
  /* CHECKED STYLES */
}

.questions fieldset [type="radio"] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.questions fieldset [type="radio"] + img {
  cursor: pointer;
}

.questions fieldset [type="radio"]:checked + img {
  outline: 6px dotted #88df86;
}

.questions button {
  border: none;
  padding: 1rem 4rem;
  background: #88df86;
  color: #f3f3f3;
  font-weight: bold;
  border-radius: 5rem;
  font-size: 1.3rem;
  margin: 2rem 0;
  margin-bottom: 4.5rem;
  cursor: pointer;
}
