.userProfile {
  width: 10rem;
  border-radius: 2rem;
  color: #F3F3F3;
  background: #88df86;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0.6rem;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-right: 10px;
  z-index: 3;
  gap: 1rem;
}

.userProfile h2 {
  margin: 0;
  font-size: 1rem;
}

.userProfile span {
  font-size: 1.2rem;
  width: 8rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.userProfile p {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
}
