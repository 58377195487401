.openMenu {
  position: absolute;
  z-index: 5;
  right: -30rem;
  -webkit-transition: .15s ease-in-out;
  transition: .15s ease-in-out;
}

.openMenu ul {
  list-style: none;
  font-size: 1.5rem;
  position: absolute;
  right: 5rem;
  bottom: 7rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 14rem;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  color: #F3F3F3;
  font-weight: bold;
  letter-spacing: 1px;
  padding-left: 4rem;
}

.openMenu ul a {
  color: #F3F3F3;
  font-size: 1rem;
  text-decoration: none;
}

.openMenu button {
  border: none;
  background: #0B3C0A;
  color: #F3F3F3;
  font-size: 1.3rem;
  border-radius: 5rem;
  width: 6rem;
  height: 3rem;
  cursor: pointer;
  -webkit-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

.openMenu button:hover {
  -webkit-transform: scale(1.1) translateY(-5px);
          transform: scale(1.1) translateY(-5px);
  color: #0B3C0A;
  background: #F3F3F3;
}

.active {
  right: -3rem !important;
}
