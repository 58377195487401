.navbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  position: fixed;
  left: 0;
  top: 1rem;
  width: 100%;
  z-index: 2;
}
