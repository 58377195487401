.loader,
.loader:before,
.loader:after {
  background: #88df86;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}

.loader {
  color: #88df86;
  text-indent: -9999em;
  margin: 88px auto;
  position: relative;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translateZ(0) translate(-50%, -50%);
          transform: translateZ(0) translate(-50%, -50%);
}

.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: "";
}

.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.loader:after {
  left: 1.5em;
}

@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    -webkit-box-shadow: 0 0;
            box-shadow: 0 0;
    height: 6em;
  }
  40% {
    -webkit-box-shadow: 0 -2em;
            box-shadow: 0 -2em;
    height: 8em;
  }
}

@keyframes load1 {
  0%,
  80%,
  100% {
    -webkit-box-shadow: 0 0;
            box-shadow: 0 0;
    height: 6em;
  }
  40% {
    -webkit-box-shadow: 0 -2em;
            box-shadow: 0 -2em;
    height: 8em;
  }
}
