.resultScreen {
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  padding: 1rem;
}

.resultScreen h1 {
  color: #88df86;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 2.5rem;
  text-align: center;
}

.resultScreen label p {
  margin-left: 1rem;
  border-bottom: 2px solid #88df86;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0 8px;
  color: #88df86;
  font-weight: bold;
  font-size: 1.2rem;
}

.resultScreen .buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  gap: 6rem;
  margin-top: 6.5rem;
  margin-bottom: 2rem;
}

.resultScreen .answers {
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: (1fr)[6];
      grid-template-rows: repeat(6, 1fr);
  margin-top: 1rem;
}

.resultScreen .answers .answer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.095);
}

.resultScreen .answers .answer .questionName {
  color: #88df86;
  font-weight: bold;
  font-size: 1.3rem;
  border-left: 2px solid #88df86;
  padding-left: 5px;
  text-align: left;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.resultScreen .answers .answer .questionName::first-letter {
  text-transform: uppercase;
}

.resultScreen .answers .answer .questionResult {
  text-align: center;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.resultScreen .answers .answer .questionResult svg {
  -webkit-transform: scale(1.4);
          transform: scale(1.4);
}

.resultScreen .answers .answer .correctAnswer {
  text-align: right;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  font-size: 1.3rem;
  font-weight: bold;
}

.resultScreen .answers .headings {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.resultScreen .answers .headings h2 {
  color: #88df86;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  text-align: center;
}

.resultScreen .answers .headings h2:first-child {
  text-align: left;
}

.resultScreen .answers .headings h2:last-child {
  text-align: right;
}
