.gameTypeButton {
  border: none;
  background: transparent;
  position: relative;
  color: #F3F3F3;
  font-size: 2rem;
  cursor: pointer;
}

.gameTypeButton p {
  z-index: 1444 !important;
  width: 20rem;
  height: 5rem;
  margin: 0;
  display: -ms-grid;
  display: grid;
  place-items: center;
}

.svgs {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: -1;
  cursor: pointer;
}
